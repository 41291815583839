<template>
  <div class="redirctPage" v-if="status===2">
    <img class="statusIcon" src="../assets/img/discernfail.png">
    <div class="statustext">审核驳回</div>
    <div class="statusDesc">驳回原因：{{ refuseReason }}</div>
    <div class="optionBtn" @click="reopt">重新上传资料</div>
  </div>
  <div class="redirctPage" v-else-if="status===0">
    <img class="statusIcon" src="../assets/img/discerning.png">
    <div class="statustext">审核处理中...</div>
    <div class="statusDesc">如需加急处理，请联系客服!</div>
    <div class="statusDesc">客服电话：400-178-7888</div>
    <div class="optionBtn" @click="next">返回详情页</div>
  </div>
  <div class="redirctPage" v-else-if="status===1">
    <img class="statusIcon" src="../assets/img/discernsucc.png">
    <div class="statustext">审核通过</div>
    <div class="statusDesc">分配银行卡已更换为尾号{{ (newBankCardNumber || '').slice(-4) }}的账户</div>
    <div class="optionBtn" @click="reopt">再次操作</div>
  </div>
  <div class="redirctPage" v-else-if="status===3">
    <img class="statusIcon" src="../assets/img/discerning.png">
    <div class="statustext">更换成功</div>
    <div class="statusDesc">分配银行卡已更换为尾号{{ (newBankCardNumber || '').slice(-4) }}的账户</div>
    <div class="optionBtn" @click="reopt">再次操作</div>
  </div>
</template>

<script>
// import { getBankCardChangeApprove, submitNewCard } from "../../interface";

export default {
  name: 'Loading',
  data() {
    return {
      status: 1,  // 状态（0:待审核,1:审核通过,2:审核拒绝）
      refuseReason: '',
      newBankCardNumber: ""
    };
  },
  computed: {},
  created() {
    this.changeCardResult();
  },
  mounted() {
  },
  methods: {
    reopt() {
      this.$router.replace({
        path: '/changeBankCard',
        query: this.$route.query
      });
    },
    next() {
      window.history.go(-1);
    },
    async changeCardResult() {
      const datas =await getBankCardChangeApprove({ reserveId: this.$route.query.reserveId });
      if (+code === 1) {
        this.status = +data.changeApprovalStatus;
        this.refuseReason = data.refuseReason;
        this.newBankCardNumber = data.newBankCardNumber;
      } else {
        this.$toast(msg);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.redirctPage {
  background:#fff;
  width: 100%;
  position: fixed;
  bottom: 0px;
  top: 0;

  .statusIcon {
    width: 180px;
    display: block;
    margin: 60px auto 0;
  }

  .statustext {
    font-size: 17px;
    text-align: center;
    margin-bottom: 30px;
  }

  .statusDesc {
    display: block;
    width: 70%;
    margin: 0 auto;
    font-weight: 400;
    color: #666;
    text-align: center;
    line-height:22px;
  }

  .optionBtn {
    width: 80%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 40px;
    background: linear-gradient(-90deg, rgba(53, 107, 255, 1), rgba(3, 152, 255, 1));
    border-radius: 100px;
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    letter-spacing:5px;
  }
}
</style>
